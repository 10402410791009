import { IUser } from 'src/interfaces/user/user.model';

export const getFullName = (user: IUser) => {
    const firstName = user.fields?.firstName ?? '-';
    const lastName = user.fields?.lastName ?? '-';

    if ((firstName === '-' && lastName === '-') || (firstName === '' && lastName === '')) {
        return user.name;
    }

    return `${firstName} ${lastName}`;
};

export const getShortName = (user: IUser) => {
    const firstName = user.fields?.firstName ?? '-';
    const lastName = user.fields?.lastName ?? '-';

    if ((firstName === '-' && lastName === '-') || (firstName === '' && lastName === '')) {
        return user.name;
    }

    return `${firstName.substring(0, 1).toUpperCase()}${
        firstName.length > 0 ? '.' : ''
    } ${lastName}`;
};
