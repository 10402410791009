import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../Auth/userAtom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { toast } from 'react-toastify';

interface IServerClientTimeCheck {}

dayjs.extend(utc);

export const ServerClientTimeCheck: React.FC<IServerClientTimeCheck> = () => {
    const user = useRecoilValue(userAtom);

    const [messageShown, setMessageShown] = useState(false);

    // TODO: Is rendered randomly sometimes
    const off = true;

    useEffect(() => {
        if (user?.currentTimeCET === null) return;

        // Get Dayjs from client
        const clientTime = dayjs();

        // Get Server Time as DAjs
        const serverTime = dayjs(user?.currentTimeCET);

        // If time difference is more that 10 minutes
        if (clientTime.diff(serverTime, 'minute') > 10 && !messageShown && !off) {
            setMessageShown(true);
            toast.warning(
                <div>
                    Die Zeit auf deinem Gerät ist nicht korrekt.
                    <div className="relative mt-2 flex w-full flex-col gap-1 text-xs">
                        <span>
                            <strong>Serverzeit ist:</strong> {serverTime.format('DD.MM.YYYY HH:mm')}{' '}
                            Uhr
                        </span>
                        <span>
                            <strong>Gerätezeit ist: </strong>
                            {serverTime.format('DD.MM.YYYY HH:mm')} Uhr
                        </span>
                    </div>
                </div>,
                { autoClose: false, closeOnClick: true }
            );
        }
    }, [messageShown, off, user?.currentTimeCET]);

    return <></>;
};
