import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IApiState {
    isRefreshing: boolean;
}

export const apiState = atom<IApiState>({
    key: `apiState/${nanoid()}`,
    default: {
        isRefreshing: false,
    },
});
