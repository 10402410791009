import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Button } from './Button';
import { useFilesystem } from '../../hooks/useFilesystem';
import { UploadIcon } from '@heroicons/react/outline';

interface IFileUpload {
    path: string[];
}

export const FileUpload: React.FC<IFileUpload> = (props) => {
    const { path } = props;
    const { saveFile } = useFilesystem();
    const fileRef = useRef<HTMLInputElement | null>(null);

    const uploadFile = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files?.[0] !== undefined) {
                const file = e.target.files[0];

                await saveFile(
                    {
                        name: file.name,
                        arrayBuffer: await file.arrayBuffer(),
                    },
                    path
                );
            }
        },
        [path, saveFile]
    );

    return (
        <>
            <Button
                onClick={() => {
                    fileRef.current?.click();
                }}
            >
                <div className="flex flex-row items-center justify-center ">
                    <UploadIcon className="mr-2 w-6 text-white" />
                    <span className="text-sm text-white">File</span>
                </div>
            </Button>
            <input
                type="file"
                name="test"
                className="hidden"
                id="test"
                ref={fileRef}
                onChange={(e) => void uploadFile(e)}
            />
        </>
    );
};
