import React from 'react';

interface IButton {
    onClick: () => void;
    addtionalCss?: string;
}

export const Button: React.FC<IButton> = (props) => {
    const { children, addtionalCss, onClick } = props;
    return (
        <button
            onClick={onClick}
            role="button"
            className="relative flex items-center justify-center rounded bg-gray-400 px-4 py-1"
        >
            <div className={`text-sm text-white ${addtionalCss ?? ''}`}> {children}</div>
        </button>
    );
};
