import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FilesystemCommander from './FilesystemCommander';
import { CollectionIcon } from '@heroicons/react/solid';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../Auth/userAtom';

export const FilesystemCommanderPlugin: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { roles } = useRecoilValue(userAtom);

    // TODO Contentful UserIDs

    return (
        <>
            {!isOpen &&
                roles &&
                (roles.some((role) => role.access.includes('admin')) ||
                    window.location.host.includes('localhost')) && (
                    <div
                        className="fixed bottom-8 left-2 z-20 h-4 w-4 rounded-full bg-neutral-800 transition  hover:scale-125"
                        onClick={() => setIsOpen(!isOpen)}
                        id="test"
                        data-test-id={`fileSystemButton`}
                    >
                        <div className="relative flex h-full w-full items-center justify-center">
                            <CollectionIcon className="w-3 text-neutral-400" />
                        </div>
                    </div>
                )}

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 w-full overflow-y-auto bg-white/50 backdrop-blur-sm"
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                >
                    <div className="relative flex h-full w-full items-end justify-center p-0 ">
                        <FilesystemCommander
                            embeded={true}
                            closeFunction={() => {
                                setIsOpen(false);
                            }}
                        />
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
