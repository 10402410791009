import React, { useCallback } from 'react';
import formatHighlight from 'json-format-highlight';
import { toast } from 'react-toastify';

interface IJSONModal {
    data: object | null;
}

export const JSONModal: React.FC<IJSONModal> = (props) => {
    const { data } = props;

    const getJSON = useCallback((): string => {
        const format = formatHighlight as (json: object) => string;

        if (!data) return '';

        return format(data);
    }, [data]);

    const copy = useCallback((text: string) => {
        navigator.clipboard.writeText(text).then(
            () => 0,
            () => 1
        );
    }, []);

    return (
        <>
            <pre
                onClick={() => {
                    copy(JSON.stringify(data) ?? '---');
                    toast('Copied');
                }}
                className="overflow-x-auto bg-gray-100 p-2 font-mono text-xs md:text-sm"
                dangerouslySetInnerHTML={{ __html: getJSON() as never }}
            />
        </>
    );
};
