import { atom, selector } from 'recoil';
import { nanoid } from 'nanoid';
import { IUser } from '../../interfaces/user/user.model';

export const userAtom = atom<IUser>({
    key: `user/${nanoid()}`,
    default: { id: '', currentTimeUTC: null, currentTimeCET: null },
});

export const getUser = selector<IUser>({
    key: `/get-user}`,
    get: ({ get }): IUser => {
        return get(userAtom);
    },
});
