import React, { useCallback, useEffect, useState } from 'react';
import { Button } from './Button';
import { FilesystemListView } from './FilesystemListView';
import { useFilesystem } from '../../hooks/useFilesystem';
import { FileUpload } from './FileUpload';
import { ChevronDoubleUpIcon, RefreshIcon, XIcon } from '@heroicons/react/solid';
import { CircleButtonLarge } from './CircleButtonLarge';
import { FolderAddIcon } from '@heroicons/react/outline';
import { nanoid } from 'nanoid';

interface IFilesystemCommander {
    embeded?: boolean;
    closeFunction?: () => void;
}

const colors: string[] = ['text-blue-200', 'text-orange-200', 'text-pink-200', 'text-green-200'];

const FilesystemCommander: React.FC<IFilesystemCommander> = (props) => {
    const { embeded, closeFunction } = props;

    const getPathFromDisc = useCallback((): string[] => {
        const savedPath = window.localStorage.getItem('fcDir');

        if (!savedPath) return [];

        const newPath = JSON.parse(savedPath) as string[];

        if (!Array.isArray(newPath)) return [];

        return newPath;
    }, []);

    const [path, setPath] = useState<string[]>(getPathFromDisc());

    const { createDirectory, updateFilesystem } = useFilesystem();

    const addToPath = useCallback(
        (name: string) => {
            setPath([...path, name]);
        },
        [setPath, path]
    );

    const removeFromPath = useCallback(() => {
        path.pop();
        setPath([...path]);
    }, [setPath, path]);

    useEffect(() => {
        // Save to disc
        window.localStorage.removeItem('fcDir');
        window.localStorage.setItem('fcDir', JSON.stringify(path));
    }, [path]);

    return (
        <>
            <div className="relative flex max-h-screen min-h-[400px] w-full max-w-7xl flex-col bg-black/70 shadow-xl backdrop-blur-sm">
                <div className="absolute left-0 top-0 flex h-full w-full bg-[#2b2b2b] bg-gradient-to-tr opacity-100" />
                <div className="container z-50 z-10  justify-center text-left md:text-center" />

                <div
                    id="filesystemHeader"
                    className="relative mb-2 flex flex-row items-center justify-center bg-[#3c3f41] p-2 md:p-4"
                >
                    {embeded && (
                        <div className="absolute left-0 flex h-full w-32 flex-row items-center justify-start">
                            <CircleButtonLarge
                                onClick={() => {
                                    if (closeFunction !== undefined) closeFunction();
                                }}
                                addtionalCss="bg-gray-300 p-1 ml-2"
                            >
                                <XIcon className="text-xl" />
                            </CircleButtonLarge>
                        </div>
                    )}
                    <div className="relative flex items-center justify-center text-sm text-gray-400">
                        Filesystem API viewer
                    </div>
                </div>

                <div id="navigation" className="z-10 flex grid-cols-12 flex-col gap-2 p-1 md:grid">
                    <div className="col-span-11 flex w-full flex-row items-center justify-center">
                        <button
                            className="mr-2 flex h-6 w-8 items-center justify-center rounded border-gray-200 bg-neutral-600 pr-1 font-mono text-sm text-xs text-neutral-400 md:w-12 md:justify-end"
                            onClick={() => removeFromPath()}
                        >
                            <span className="hidden md:flex">root/</span>
                            <span className="flex md:hidden">/</span>
                        </button>

                        <div className="flex h-6 w-full cursor-not-allowed items-center justify-start rounded bg-[#3c3f41] p-2 font-mono text-xs text-gray-400">
                            {path?.map((item, index) => (
                                <div key={nanoid()}>
                                    <span className={colors[index] ?? ''} key={nanoid()}>
                                        {item}
                                    </span>

                                    {path.length - 1 > index && (
                                        <span className="mx-1 inline-flex w-1" key={nanoid()}>
                                            /
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-1 flex w-full flex-row items-end justify-end ">
                        <CircleButtonLarge
                            onClick={() => {
                                removeFromPath();
                            }}
                            addtionalCss="bg-gray-300 p-1 mx-1"
                        >
                            <ChevronDoubleUpIcon className="text-sm" />
                        </CircleButtonLarge>
                        <CircleButtonLarge
                            onClick={() => {
                                updateFilesystem();
                            }}
                            addtionalCss="bg-gray-300 p-1 mx-1"
                        >
                            <RefreshIcon className="text-sm" />
                        </CircleButtonLarge>
                    </div>
                </div>

                <div id="body" className="p-4">
                    <FilesystemListView
                        path={path}
                        setDir={(name: string) => {
                            addToPath(name);
                        }}
                    />
                </div>

                <div id="footer" className="flex grid-cols-2 flex-col gap-2 md:grid ">
                    <FileUpload path={path ?? []} />
                    <Button
                        onClick={() => {
                            const dirName = window.prompt('Name:');
                            if (dirName && dirName.length > 0) {
                                void createDirectory(dirName, path);
                            }
                        }}
                    >
                        <div className="flex flex-row items-center justify-center ">
                            <FolderAddIcon className="mr-2 w-6 text-white" />
                            <span className="text-sm text-white">Directory</span>
                        </div>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default React.memo(FilesystemCommander);
