import React from 'react';

interface ICircleButton {
    onClick: () => void;
    addtionalCss?: string;
}

export const CircleButtonLarge: React.FC<ICircleButton> = (props) => {
    const { onClick, addtionalCss, children } = props;

    return (
        <button
            onClick={onClick}
            role="button"
            className={`relative h-8 w-8 rounded-full border-gray-500 bg-red-600 opacity-50 hover:opacity-100 md:h-6 md:w-6 ${
                addtionalCss ?? ''
            }`}
        >
            {children}
        </button>
    );
};
