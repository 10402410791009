import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import localforage from 'localforage';

export const usePersistentContext = <T>(
    baseKey: string,
    data: T,
    setData: Dispatch<SetStateAction<T>>
) => {
    const [currentData, setCurrentData] = useState<T | null>(null);

    const [isSet, setIsSet] = useState(false);

    const getFromCache = useCallback(async () => {
        if (isSet) return null;

        const lf = localforage.createInstance({
            name: 'context',
            storeName: 'context',
            version: 4,
        });

        const cache = (await lf.getItem(baseKey)) as T;

        // Set with data from cache
        if (cache) setIsSet(true);
        if (cache) setCurrentData(cache);
        if (cache) setData(cache);
        if (cache === null) setCurrentData(data);
    }, [baseKey, data, isSet, setData]);

    const setPersistentData = useCallback(
        async (newState: T) => {
            if (baseKey === undefined) return null;
            const lf = localforage.createInstance({
                name: 'context',
                storeName: 'context',
                version: 4,
            });
            await lf.setItem(baseKey, newState);
            setData(newState);
        },
        [baseKey, setData]
    );

    useEffect(() => {
        if (isSet) return undefined;

        // Get from cache once a render
        if (currentData === null) {
            setIsSet(true);
            void getFromCache();
        }
    }, [baseKey, currentData, getFromCache, isSet]);

    useEffect(() => {
        if (isSet) return undefined;

        if (currentData !== null) setCurrentData(data);
    }, [currentData, data, isSet]);

    return { persistentData: currentData, setPersistentData };
};
