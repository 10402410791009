import {useCallback, useEffect} from 'react';
import {IWriteFileWorkerResponse} from '../worker/writefile.worker';
import {IFileWrite} from './useFilesystem';
import dayjs from 'dayjs';
import {useFilesystemContext} from '../context/filesystemContext';
import {useLatest} from 'react-use';
import {toast} from 'react-toastify';
import {useRecoilState} from 'recoil';
import {saveWorkerRecoil} from '../recoil/atoms/saveWorker';

// https://webkit.org/blog/12257/the-file-system-access-api-with-origin-private-file-system/
// https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
// https://storage.spec.whatwg.org/#storage-endpoints
// https://developers.google.com/web/updates/2011/08/Debugging-the-Filesystem-API
// filesystem:http://localhost:8700/temporary/

// TODO In Safari on macOS 12.4 and iOS 15.4, we introduced the getFile() method of FileSystemFileHandle.
export interface IDirectoryContents {
    directories: string[];
    files: string[];
}

export const useFilesystemWorker = () => {
    const [filesystem, setFilesystem] = useFilesystemContext();
    const filesystemLatest = useLatest(filesystem);

    const isSafari =
        typeof window === 'undefined' ||
        typeof window.navigator === 'undefined' ||
        typeof window.navigator.userAgent === 'undefined'
            ? false
            : /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

    const updateFilesystem = useCallback(() => {
        setFilesystem({ ...filesystemLatest.current, lastUpdate: dayjs().toISOString() });
    }, [filesystemLatest, setFilesystem]);

    // const [saveWorker, setSaveWorker] = useState<Worker | null>(null);
    const [saveWorker, setSaveWorker] = useRecoilState(saveWorkerRecoil);

    const saveFileWorker = useCallback(
        (
            file: IFileWrite,
            path: string[],
            options?: {
                onFinish: () => Promise<void> | void;
            }
        ) => {
            if (saveWorker.worker) {
                saveWorker.worker.onmessage = (evt) => {
                    const data = evt.data as IWriteFileWorkerResponse;
                    // eslint-disable-next-line no-console
                    console.log('!!! SAVED', data.filename);

                    updateFilesystem();
                    void options?.onFinish();
                };

                saveWorker.worker.postMessage({
                    msg: 'save',
                    arrayBuffer: file.arrayBuffer,
                    filename: file.name,
                    path,
                });
            } else {
                toast.error('Fehler beim Speicher: Web-Worker des Geräts nicht aktiv');
            }
        },
        [saveWorker, updateFilesystem]
    );

    useEffect(() => {
        if (isSafari && saveWorker.worker === null) {
            // TODO INCOMPATIBLE with Jest src... -> SAFARI abfrage mit einbauen, aus useFilesystem()
            const sworker = new Worker(new URL('/src/worker/writefile.worker.ts', import.meta.url));
            setSaveWorker({
                ...saveWorker,
                worker: sworker,
            });
        }
    }, [isSafari, saveWorker, setSaveWorker]);

    return {
        saveFileWorker,
    };
};
