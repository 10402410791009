import React, { useEffect } from 'react';
import { useUser } from '../hooks/user/useUser';
import * as Sentry from '@sentry/nextjs';
import { getFullName } from '../helper/userFields';

export const SentryInsert: React.FC = () => {
    const { data: user } = useUser(true);

    useEffect(() => {
        // if (user && process.env.NODE_ENV === 'production') {
        if (user) {
            // eslint-disable-next-line no-console
            console.log('SENTRY!!!', user, process.env.NODE_ENV);

            // Sentry.init({
            //     dsn: 'https://e7d70f42949b4e368aa3232329bbe038@o452154.ingest.sentry.io/6455638',
            //     integrations: [new Integrations.BrowserTracing()],
            //     environment: process.env.NODE_ENV,
            //     release: '2.x',
            //     // Set tracesSampleRate to 1.0 to capture 100%
            //     // of transactions for performance monitoring.
            //     // We recommend adjusting this value in production
            //     tracesSampleRate: 1.0,
            // });

            Sentry.setContext('user', {
                name: getFullName(user) ?? 'Anonymous',
                userName: user.name ?? 'Unknown',
            });

            Sentry.setUser({
                id: user.id,
                name: getFullName(user) ?? 'Anonymous',
                username: user.name ?? 'Anonymous',
                email: user.email ?? '',
            });
        }
    }, [user]);

    return <></>;
};
