import { atom } from 'recoil';
import { v4 } from 'uuid';

interface ISaveWorkerAtom {
    worker: Worker | null;
}

export const saveWorkerRecoil = atom<ISaveWorkerAtom>({
    key: `save-worker-state/${v4()}`,
    default: {
        worker: null,
    },
});
