import React, { useCallback, useEffect, useState } from 'react';
import { IReadFile, useFilesystem } from '../../hooks/useFilesystem';
import { IDirectoryContents } from '../../hooks/useFilesystemWorker';
import { v4 as uuidv4 } from 'uuid';
import { FolderIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import Thumbnail from '../atoms/Thumbnail';
import formatBytes from '../../utils/formatBytes';
import dayjs from 'dayjs';
import { useFilesystemContext } from '../../context/filesystemContext';
import { CircleButtonLarge } from './CircleButtonLarge';
import { JSONModal } from '../atoms/JSONModal';
import { Modal } from '../atoms/Modal/Modal';

interface IFileSystemListView {
    path: string[];
    setDir: (name: string) => void;
}

interface IFilesystemList {
    [key: string]: IReadFile;
}

export const FilesystemListView: React.FC<IFileSystemListView> = (props) => {
    const { getDirectoryContents, readFile, deleteFilesInDir } = useFilesystem();

    const [filesystem] = useFilesystemContext();

    const [showFileModal, setShowFileModal] = useState(false);
    const [modalData, setModalData] = useState<object | null>(null);
    const [modalDataTitle, setModalDataTitle] = useState('');

    const { setDir, path } = props;

    const [content, setContent] = useState<null | IDirectoryContents>(null);
    const [files, setFiles] = useState<IFilesystemList | null>(null);
    const [directorySize, setDirectorySize] = useState<number | null>(null);

    const loadFile = useCallback(
        async (contents: IDirectoryContents) => {
            const promises: Promise<IReadFile | null>[] = [];

            contents?.files.forEach((name) => {
                promises.push(readFile(name, path, { response: 'plain' }));
            });

            const newFileList: IFilesystemList = {};

            await Promise.all(promises).then((res) => {
                let sumSize = 0;

                res.forEach((fileData) => {
                    if (!fileData) return null;

                    if (fileData.file === undefined) return null;

                    newFileList[fileData.file.name] = fileData;

                    sumSize += fileData.file.size;
                });
                setFiles(newFileList);

                setDirectorySize(sumSize);
            });
        },
        [readFile, setFiles, setDirectorySize, path]
    );

    useEffect(() => {
        // Read Directory
        void getDirectoryContents(path ?? []).then((r) => {
            if (!r) return null;

            // Add root
            r.directories.unshift('');
            const newContent = { ...r, directories: r.directories };
            void loadFile(newContent);
            setContent(newContent);
        });
    }, [getDirectoryContents, loadFile, setContent, filesystem.lastUpdate, path]);

    const deleteItem = useCallback(
        async (filenames: string | string[]) => {
            await deleteFilesInDir(filenames, path);
        },
        [path, deleteFilesInDir]
    );

    const getFileType = useCallback(
        (
            filename: string
        ): {
            extension: string;
            type: 'image' | 'json' | 'text' | 'unknown';
        } => {
            const r = new RegExp(/\.([a-zA-Z0-9]+)$/);

            const extension = filename.toLocaleLowerCase().match(r);

            if (extension && extension[1] !== undefined) {
                switch (extension[1]) {
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                    case 'gif':
                        return {
                            extension: extension[1],
                            type: 'image',
                        };
                    case 'json':
                        return {
                            extension: extension[1],
                            type: 'json',
                        };
                    case 'txt':
                        return {
                            extension: extension[1],
                            type: 'text',
                        };
                }
            }

            return {
                extension: '',
                type: 'unknown',
            };
        },
        []
    );

    return (
        <div className="relative grid gap-1">
            <div className="relative flex w-full flex-row justify-end">
                <div className="text-xs text-gray-500">
                    <span>Total: </span>
                    <span className="text-primary">
                        {directorySize !== null && formatBytes(directorySize, 2)}
                    </span>
                </div>
            </div>

            {content !== null &&
                files !== null &&
                content.directories.map((name) => {
                    if (name === '') return <div key={uuidv4()} />;

                    return (
                        <div
                            className="relative grid h-10 w-full cursor-pointer grid-cols-12 gap-2 hover:bg-neutral-900"
                            key={uuidv4()}
                        >
                            <div
                                className="col-span-2 flex items-center justify-center bg-gradient-to-tr from-gray-700 via-gray-700 to-gray-900"
                                onClick={() => setDir(name)}
                            >
                                <FolderIcon className="col-span-1 w-6 text-white" />
                            </div>
                            <span
                                className="col-span-8 flex items-center justify-start text-xs text-gray-500 sm:text-sm"
                                onClick={() => setDir(name)}
                            >
                                {name !== '' && name}
                                {name === '' && '/'}
                            </span>
                            <div className="col-span-2 flex flex-row items-end justify-end">
                                {name !== '' && (
                                    <CircleButtonLarge
                                        addtionalCss="bg-gray-300 mr-1"
                                        onClick={() => void deleteItem(name)}
                                    >
                                        <TrashIcon className="mx-auto h-5" />
                                    </CircleButtonLarge>
                                )}
                            </div>
                        </div>
                    );
                })}

            {content !== null &&
                files !== null &&
                content.files.map((name) => {
                    return (
                        <div
                            className="relative grid w-full grid-cols-12 items-center justify-center gap-2 hover:bg-neutral-900"
                            key={uuidv4()}
                        >
                            {files[name] !== undefined && (
                                <>
                                    <div className="aspect-w-4 aspect-h-3 relative col-span-4 flex items-center justify-center overflow-hidden p-0 py-2 sm:col-span-2">
                                        {getFileType(name).type === 'json' && (
                                            <button
                                                onClick={() => {
                                                    setModalData(
                                                        JSON.parse(
                                                            files[name]?.data ?? '{}'
                                                        ) as object
                                                    );
                                                    setShowFileModal(true);
                                                    setModalDataTitle(name);
                                                }}
                                                className="relative flex h-full w-full items-center justify-center bg-neutral-400 py-2"
                                            >
                                                JSON
                                            </button>
                                        )}

                                        {getFileType(name).type === 'image' && (
                                            <a
                                                href={files[name]?.url ?? ''}
                                                target="filepreview"
                                                className="relative flex items-center justify-center"
                                            >
                                                <Thumbnail url={files[name]?.url ?? ''} />
                                            </a>
                                        )}
                                    </div>

                                    <span className="col-span-6 flex items-center justify-start text-xs sm:col-span-8 sm:text-sm">
                                        <div className="relative flex w-full grid-cols-12 flex-col gap-1 md:grid">
                                            <span className="col-span-6 text-sm text-neutral-300">
                                                {name}
                                            </span>
                                            <span className="col-span-4 text-xs text-gray-500 md:text-sm">
                                                {dayjs(
                                                    files[name]?.file?.lastModified ?? ''
                                                ).format('DD.MM.YYYY HH:MM')}
                                            </span>
                                            <span className="col-span-2 text-right text-xs text-gray-500 md:text-sm">
                                                {formatBytes(files[name]?.file?.size ?? 0)}
                                            </span>
                                        </div>
                                    </span>
                                    <div className="col-span-2 flex flex-row items-end justify-end pr-1 text-right">
                                        <CircleButtonLarge
                                            addtionalCss="bg-gray-300"
                                            onClick={() => void deleteItem(name)}
                                        >
                                            <TrashIcon className="mx-auto h-5" />
                                        </CircleButtonLarge>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}

            <Modal
                show={showFileModal}
                afterClose={() => setShowFileModal(false)}
                title={modalDataTitle}
                closeable={true}
            >
                <JSONModal data={modalData} />
            </Modal>
        </div>
    );
};
