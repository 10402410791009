import { useSetRecoilState } from 'recoil';
import { apiState } from '../../recoil/request';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { refresh } from '../../api/auth/refresh';
import { getRedirectUrl } from '../../components/Auth/authUtil';

export const useApiRefresh = () => {
    const setRequestState = useSetRecoilState(apiState);
    const { push, pathname } = useRouter();
    const refreshCB = useCallback(async () => {
        if (!pathname.startsWith('/login')) {
            setRequestState((context) => {
                return { ...context, isRefreshing: true };
            });

            return refresh()
                .then(() => {
                    setRequestState((context) => {
                        return {
                            ...context,
                            isRefreshing: false,
                        };
                    });
                    return true;
                })
                .catch(async () => {
                    await push(getRedirectUrl());
                    return false;
                });
        }
        return false;
    }, [pathname, push, setRequestState]);

    return { refresh: refreshCB };
};
