import createStateContext from 'react-use/lib/factory/createStateContext';
import DeviceDetector from 'device-detector-js';

export interface IAppContext {
    deviceData: DeviceDetector.DeviceDetectorResult | null;
}

export const DefaultFieldsValues: IAppContext = {
    deviceData: null,
};

export const [useAppContext, AppContextProvider] =
    createStateContext<IAppContext>(DefaultFieldsValues);
