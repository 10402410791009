import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import React from 'react';
import { AppWrapper } from '../src/components/AppWrapper/AppWrapper';

const App = ({ Component, pageProps }: AppProps): unknown => {
    // TODO Umbau wegen Cypress tests
    return <AppWrapper Component={Component} pageProps={pageProps as unknown} />;
};

export default App;
