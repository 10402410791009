import createStateContext from 'react-use/lib/factory/createStateContext';

export interface ISyncContext {
    syncing: boolean;
    position: number;
    count: number;
    firstSync: boolean;
}

const DefaultFieldsValues: ISyncContext = {
    syncing: false,
    position: 0,
    count: 0,
    firstSync: false,
};

export const [useSyncContext, SyncContextProvider] =
    createStateContext<ISyncContext>(DefaultFieldsValues);
