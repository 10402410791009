import React from 'react';
import { SyncContextProvider } from '../../context/syncContext';
import { FilesystemContextProvider } from '../../context/filesystemContext';
import { AppContextProvider } from '../../context/appContext';
import { BLFormContextProvider } from '../BLForms/context/BLFormContext';
import { BLFormContextSettingsProvider } from '../BLForms/context/BLFormContextSettings';
import { RecoilRoot } from 'recoil';
import { SWUpdater } from '../atoms/SWUpdater/SWUpdater';
import { SentryInsert } from '../sentry';
import { Flip, ToastContainer } from 'react-toastify';
import { FilesystemCommanderPlugin } from '../FilesystemCommander/FilesystemCommanderPlugin';
import { QueryClient } from 'react-query';
import { createAsyncStoragePersister } from 'react-query/createAsyncStoragePersister';
import { lfStorage } from '../../helper/offline';
import { NextComponentType, NextPageContext } from 'next';
import { PersistedClient, PersistQueryClientProvider } from 'react-query/persistQueryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ServerClientTimeCheck } from '../ServerClientTimeCheck/ServerClientTimeCheck';
import Jimp from 'jimp';
import JPEG from 'jpeg-js';

interface IAppWrapper {
    Component: NextComponentType<NextPageContext>;
    pageProps: unknown;
}

/**
 *      from jpeg-js: node_modules
 *     colorTransform: undefined,
 *     useTArray: false,
 *     formatAsRGBA: true,
 *     tolerantDecoding: true,
 *     maxResolutionInMP: 100, // Don't decode more than 100 megapixels
 *     maxMemoryUsageInMB: 512, // Don't decode if memory footprint is more than 512MB
 */

// Search for all occurancies of this setting, they be be overwritten by the next line
Jimp.decoders['image/jpeg'] = (data: Buffer) => JPEG.decode(data, { maxMemoryUsageInMB: 1024 });

export const AppWrapper: React.FC<IAppWrapper> = (props) => {
    const { Component, pageProps } = props;

    // Create a client
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                cacheTime: Infinity, // 24 hours
                staleTime: 10000,
                retry: 1,
            },
            mutations: {
                retry: false,
            },
        },
    });

    const persister = createAsyncStoragePersister({
        storage: lfStorage,
        throttleTime: 100,
        deserialize: (cachedString) => cachedString as unknown as PersistedClient,
        serialize: (client) => client as unknown as string,
    });

    return (
        <>
            <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister, maxAge: Infinity }}
            >
                <SyncContextProvider>
                    <FilesystemContextProvider>
                        <AppContextProvider>
                            <BLFormContextProvider>
                                <BLFormContextSettingsProvider>
                                    <RecoilRoot>
                                        <Component {...(pageProps as never)} />
                                        <SWUpdater />
                                        <SentryInsert />
                                        <ServerClientTimeCheck />
                                        <ToastContainer
                                            position="top-center"
                                            autoClose={3500}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            transition={Flip}
                                        />
                                        <FilesystemCommanderPlugin />
                                        <ReactQueryDevtools />
                                    </RecoilRoot>
                                </BLFormContextSettingsProvider>
                            </BLFormContextProvider>
                        </AppContextProvider>
                    </FilesystemContextProvider>
                </SyncContextProvider>
            </PersistQueryClientProvider>
        </>
    );
};
