import React, { useEffect, useState } from 'react';
import { useFilesystem } from '../../hooks/useFilesystem';

interface IThumbnailProps {
    filename?: string;
    url?: string;
    path?: string[];
}

const Thumbnail: React.FC<IThumbnailProps> = (props) => {
    const { readFile } = useFilesystem();

    const { filename, url, path } = props;

    const [src, setSrc] = useState<string | null>(null);

    useEffect(() => {
        if (url === undefined) {
            void readFile(filename ?? '', path ?? []).then((file) => {
                if (file && file.url) setSrc(file.url ?? '');
                if (file && file.data) setSrc(`data:image/jpg;base64, ${file.data}`);
            });
        }

        return () => {
            setSrc(null);
        };
    }, [filename, path, readFile, url]);

    return (
        <>
            {(src || url) && (
                <img
                    src={url === undefined ? src ?? '' : url ?? ''}
                    alt={filename}
                    className="relative h-full w-full object-contain"
                />
            )}
        </>
    );
};

Thumbnail.defaultProps = {
    path: [],
};

export default Thumbnail;
